import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { getJamTrack } from '../../helpers/rest';

const JKCheckoutSuccess = () => {
  const { t } = useTranslation('checkoutSuccess');
  const search = useLocation().search;
  const [free, setFree] = useState(false);
  const [jamtrack, setJamtrack] = useState(null);

  useEffect(() => {
    if (!search) return;
    const params = new URLSearchParams(search);
    setFree(params.get('free') === 'yes');
    const jamTrackId = params.get('jamtrackId');
    if (jamTrackId) {
      getJamTrack({ id: jamTrackId }).then(resp => {
        if (resp.ok) {
          resp.json().then(data => {
            setJamtrack(data);
          });
        }
      });
    }
  }, [search]);

  return (
    <Card>
      <FalconCardHeader title={t('page_title')} titleClass="font-weight-semi-bold" />
      <CardBody className="pt-0 text-center mt-5 mb-5">
        {free ? (
          <>
            <p>
              Thank you for getting your first JamTrack&nbsp;
              {jamtrack && (
                <>
                  &quot;{jamtrack.name}&quot; free! Click the button below to start playing with your JamTrack in your
                  browser. <br />
                </>
              )}
            </p>
            {jamtrack && (
              <Link to={`/jamtrack/${jamtrack.id}`} className="btn btn-primary">
                {t('open_jamtrack')}
              </Link>
            )}
          </>
        ) : (
          <>
            <p>
              Thank you for your order! We'll send you an order confirmation email shortly.
              <br />
              Click the button below to start using your new JamTracks.
            </p>
            <p>
              <Link to="/my-jamtracks" className="btn btn-primary">
                {t('my_jamtracks')}
              </Link>
            </p>
          </>
        )}

        <div className="mt-6">
          <p>
            You can also play with your JamTracks in the{' '}
            <a href="https://www.jamkazam.com/downloads" target="_blank">
              JamKazam desktop app
            </a>
            , available for Windows and Mac.
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default JKCheckoutSuccess;
