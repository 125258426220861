import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import JKJamTracksAutoComplete from '../jamtracks/JKJamTracksAutoComplete';
import { getJamTracks, getJamTrackArtists, getAffiliatePartnerData, autocompleteJamTracks } from '../../helpers/rest';
import { useAuth } from '../../context/UserAuth';
import { useHistory } from 'react-router-dom';
import { useResponsive } from '@farfetch/react-context-responsive';
import { Link } from 'react-router-dom';

const JKAffiliateLinks = () => {
  const { t } = useTranslation('affiliate');
  const { greaterThan } = useResponsive();
  const { currentUser } = useAuth();
  const history = useHistory();
  const [affiliatePartnerId, setAffiliatePartnerId] = useState('xxxxx');
  const [affiliate, setAffiliate] = useState(null);
  const [jamTracks, setJamTracks] = useState([]);
  const [jamTrackArtists, setJamTrackArtists] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  const [jamTracksNextPage, setJamTracksNextPage] = useState(null);

  useEffect(() => {
    fetchAffiliate();
  }, []);

  const fetchAffiliate = async () => {
    try {
      const response = await getAffiliatePartnerData(currentUser.id);
      const affiliate = await response.json();
      if (affiliate) {
        setAffiliate(affiliate);
        setAffiliatePartnerId(affiliate.account.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchJamTracks = async options => {
    try {
      const resp = await getJamTracks(options);
      const data = await resp.json();
      console.log('data', data);
      setJamTrackArtists([]);
      setJamTracks(data.jamtracks);
      setJamTracksNextPage(data.next);
    } catch (error) {}
  };

  const fetchJamTrackArtists = async options => {
    try {
      const resp = await getJamTrackArtists(options);
      const data = await resp.json();
      console.log('data', data);
      setJamTracks([]);
      setJamTrackArtists(data.artists);
    } catch (error) {}
  };

  const queryOptions = selected => {
    const options = {
      limit: 100
    };

    if (typeof selected === 'string') {
      options.search = selected;
      return options;
    }

    if (selected.type === 'artist') {
      options.artist = selected.original_artist;
    } else {
      options.song = selected.name;
    }

    if (jamTracksNextPage !== null) {
      options.next = jamTracksNextPage;
    }
    return options;
  };

  const homePageLink = useMemo(() => `${process.env.REACT_APP_BASE_URL}?affiliate=${affiliatePartnerId}`, [affiliatePartnerId]);

  const jamTrackLink = useMemo(() => jamTrack => {
    if (!jamTrack) {
      return '';
    }
    return `${process.env.REACT_APP_BASE_URL}/public/jamtracks/${encodeURIComponent(jamTrack.original_artist_slug)}/${jamTrack.name_slug}?affiliate=${affiliatePartnerId}`;
  }, [affiliatePartnerId]);

  const jamTrackArtistLink = useMemo(() => artist => {
    if (!artist) {
      return '';
    }
    return `${process.env.REACT_APP_BASE_URL}/public/jamtracks/${encodeURIComponent(artist.original_artist_slug)}?affiliate=${affiliatePartnerId}`;
  }, [affiliatePartnerId]);

  //autocomplete related code
  const handleOnSelect = selected => {
    console.log('onSelect', selected);
    const params = queryOptions(selected);
    if(selected.type === 'artist') {
      fetchJamTrackArtists(params);
    }else {
      fetchJamTracks(params);
    }
  };

  const handleOnEnter = queryStr => {
    console.log('onEnter', queryStr);
    const params = queryOptions(queryStr);
    fetchJamTracks(params);
  };

  return (
    <Card style={{ width: greaterThan.sm ? '75%' : '100%' }} className="mx-auto affiliate-links">
      <FalconCardHeader title={t('links.page_title')} titleClass="font-weight-semi-bold" />
      <CardBody className="pt-3 pb-5">
        <p>{t('links.paragraph1')}</p>
        <div className="affiliate-links__subtitle">{t('links.home_page_subtitle')}</div>
        <p>
          {t('links.home_page_paragraph')}:{' '}
          {affiliate ? (
            <a href={homePageLink} target="_blank">
              {homePageLink}
            </a>
          ) : (
            <a href="javascript:void(0);" onClick={() => history.push('/affiliate/agreement')}>
              {homePageLink}
            </a>
          )}
        </p>
        <div className="affiliate-links__subtitle">{t('links.jamtracks_pages_subtitle')}</div>
        <p>{t('links.jamtracks_pages_paragraph')}</p>
        <div className="mt-4">
          <JKJamTracksAutoComplete
            fetchFunc={autocompleteJamTracks}
            onSelect={handleOnSelect}
            onEnter={handleOnEnter}
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            inputValue={autoCompleteInputValue}
            setInputValue={setAutoCompleteInputValue}
            inputPlaceholder={t('search.search_input.placeholder', { ns: 'jamtracks' })}
          />
        </div>
        {jamTracks &&
          jamTracks.map(jamTrack => {
            return (
              <div key={jamTrack.id} className='mb-3'>
                <div>
                  {t('links.affiliate_link_for')} {jamTrack.name} {t('links.by')} {jamTrack.original_artist}:
                </div>
                <div>
                  {affiliate ? (
                    // <a href={jamTrackLink(jamTrack)} target="_blank">
                    //   {jamTrackLink(jamTrack)}
                    // </a>
                    <Link to={`/public/jamtracks/${jamTrack.original_artist_slug}/${jamTrack.name_slug}`}>
                      {jamTrackLink(jamTrack)}
                    </Link>
                  ) : (
                    // <a href="javascript:void(0);" onClick={() => history.push('/affiliate/agreement')}>
                    //   {jamTrackLink(jamTrack)}
                    // </a>
                    <Link to={`/affiliate/agreement`} target="_blank">
                      {jamTrackLink(jamTrack)}
                    </Link>
                  )}
                </div>
              </div>
            );
        })}

        {jamTrackArtists &&
          jamTrackArtists.map(artist => {
            return (
              <div key={artist.original_artist} className='mb-3'>
                <div>
                  {t('links.affiliate_link_for')} {artist.original_artist}:
                </div>
                <div>
                {affiliate ? (
                    // <a href={jamTrackArtistLink(artist)} target="_blank">
                    //   {jamTrackArtistLink(artist)}
                    // </a>
                    <Link to={`/public/jamtracks/${artist.original_artist_slug}`}>
                      {jamTrackArtistLink(artist)}
                    </Link>
                  ) : (
                    // <a href="javascript:void(0);" onClick={() => history.push('/affiliate/agreement')}>
                    //   {jamTrackArtistLink(artist)}
                    // </a>
                    <Link to={`/affiliate/agreement`} target="_blank">
                      {jamTrackArtistLink(artist)}
                    </Link>
                  )}
                </div>
              </div>
            );
        })}
      </CardBody>
    </Card>
  );
};

export default JKAffiliateLinks;
