import React from 'react';
import { Row, Col, Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import JKJamTrackItem from './JKJamTrackItem';

const JKJamTracksList = ({ selectedType, searchTerm, jamTracks, nextOffset, onNextPage }) => {
  const { t } = useTranslation('jamtracks');
  return (
    <>
      {jamTracks.length > 0 && (
        <Row>
          <Col>
            <Table striped bordered className="fs-0" data-testid="jamtracks-table">
              <thead className="bg-200 text-900">
                <tr>
                  <th width="30%">{t('search.list.song')}</th>
                  <th width="55%">{t('search.list.tracks')}</th>
                  <th className='text-center' align='center'>{t('search.list.shop')} </th>
                </tr>
              </thead>
              <tbody>
                {jamTracks.map((jamTrack, index) => (
                  <JKJamTrackItem key={index} jamTrack={jamTrack} />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {nextOffset && (
        <Row>
          <Col>
            <Button color="primary" onClick={onNextPage} data-testid="moreBtn">
              {t('search.list.load_more')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

JKJamTracksList.propTypes = {
  selectedType: PropTypes.string || null,
  searchTerm: PropTypes.string,
  jamTracks: PropTypes.array,
  nextOffset: PropTypes.number,
  onNextPage: PropTypes.func
};

JKJamTracksList.defaultProps = {
  selectedType: null,
  searchTerm: '',
  jamTracks: [],
  nextOffset: null,
  onNextPage: () => { }
};

export default JKJamTracksList;
