import React, { useEffect, useState } from 'react';
import jkCustomUrlScheme from '../../helpers/jkCustomUrlScheme';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const JKCustomUrlSchemaHandle = ({ customQueryString, toggle }) => {
  const [urlScheme, setUrlScheme] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    //const queryStr = window.location.search;
    if (!customQueryString) return;
    const urlParams = new URLSearchParams(customQueryString);
    const action = urlParams.get('act');
    const params = urlParams.get('p');
    const appUrl = jkCustomUrlScheme(action, params);
    setUrlScheme(appUrl);
  }, []);

  useEffect(() => {
    if (urlScheme) {
      console.log('opening custom url', urlScheme);
      window.open(urlScheme, '_self');
    }
  }, [urlScheme]);

  return (
    <div>
      <Row>
        <Col>
          <div className="mt-8 text-center mb-8">
            {urlScheme && (
              <>
                <p>
                  Click <strong>Open JamKazam.app</strong> on the dialog shown by the browser. <br />
                  If you don't see the dialog, click <strong>Lauhch JamKazam App</strong> below.
                </p>
                <div>
                  <a href={urlScheme} className='btn btn-primary' onClick={toggle}>Lauhch JamKazam App</a>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default JKCustomUrlSchemaHandle;
