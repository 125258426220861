import React from 'react';
import useUserProfile from '../hooks/useUserProfile';
import { useAuth } from './UserAuth';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// AppDataContext.js
// this context is used to store app data that is shared across the app
const AppDataContext = React.createContext(null);

export const AppDataProvider = ({ children }) => {
  const [appData, setAppData] = React.useState({});
  const { currentUser } = useAuth();
  const { userProfile, photoUrl } = useUserProfile({ user: currentUser, useCache: false });

  React.useEffect(() => {
    const fpPromise = FingerprintJS.load();
    fpPromise.then(fp => {
      fp.get().then(result => {
        console.log('visitorId', result);
        setAppData(prev => ({...prev, visitorId: result.visitorId}));
      });
    });
  }, [])
  
  React.useEffect(() => {
    setAppData(prev => ({...prev, userProfile, currentUserPhotoUrl: photoUrl}));
  }, [userProfile, photoUrl]);

  return <AppDataContext.Provider value={{ appData, setAppData }}>{children}</AppDataContext.Provider>;
};

export const useAppData = () => React.useContext(AppDataContext);