import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/UserAuth';
import { updateUser } from '../../helpers/rest';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import JKTooltip from '../common/JKTooltip';

const JKAccountPreferences = () => {
  const { t } = useTranslation('account');
  const { currentUser } = useAuth();
  const [selectedPref, setSelectedPref] = useState(null);
  const [selectedPrefV2, setSelectedPrefV2] = useState(null);

  const {
      register,
      setValue
    } = useForm();

  useEffect(() => {
    if (currentUser) {
      setValue('recording_pref', currentUser.recording_pref);
      setSelectedPrefV2(currentUser.recording_pref);
    }
  }, [currentUser]);

  //save the selected preference to the database
  useEffect(() => {
    if (!currentUser || selectedPref === null) {
      return;
    }
   
    const params = {
      recording_pref: selectedPref
    }

    updateUser(currentUser.id, params).then(response => {
      setSelectedPrefV2(selectedPref);
      toast.success(t('preferences.recording_preferences.save_success'));
    }).catch(error => {
      console.log(error);
      toast.error(t('preferences.recording_preferences.save_error'));
    });
    
  }, [selectedPref]);

  return (
    <Card>
      <FalconCardHeader title={t('preferences.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3" style={{ backgroundColor: '#edf2f9' }}>
        <Row>
          <Col className='mb-2'>
            <Card>
              <CardHeader>
                <h5>{t('preferences.recording_preferences.title')}</h5>
              </CardHeader>
              <CardBody className="bg-light" style={{ minHeight: 300 }}>
                <small>
                  {t('preferences.recording_preferences.help_text')}
                </small>

                <Form noValidate className="mt-2 pl-4">
                  <FormGroup>
                    <Input
                    {...register('recording_pref')}
                    checked={selectedPref === 0 || selectedPrefV2 === 0}
                    type='radio'
                    value="0" 
                    data-testid="recording_pref_none"
                    onClick={() => setSelectedPref(0)} />
                    <Label for="exampleSelect">{t('preferences.recording_preferences.options.none')}</Label>
                  </FormGroup>
                  <FormGroup>
                    <Input
                    {...register('recording_pref')}
                    checked={selectedPref === 1 || selectedPrefV2 === 1}
                    type='radio'
                    value="1"
                    data-testid="recording_pref_my_audio"
                    onClick={() => setSelectedPref(1)} />
                    <Label for="exampleSelect" className='mr-1'>{t('preferences.recording_preferences.options.my_audio')}<JKTooltip title={t('preferences.recording_preferences.options.my_audio_tooltip')} /></Label>
                    
                  </FormGroup>
                  <FormGroup>
                    <Input
                    {...register('recording_pref')}
                    checked={selectedPref === 2 || selectedPrefV2 === 2}
                    type='radio'
                    value="2"
                    data-testid="recording_pref_my_audio_and_session_mix"
                    onClick={() => setSelectedPref(2)}  />
                    <Label for="exampleSelect" className='mr-1'>{t('preferences.recording_preferences.options.my_audio_and_session_mix')}<JKTooltip title={t('preferences.recording_preferences.options.my_audio_and_session_mix_tooltip')} /></Label>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col className='d-none d-lg-block'></Col>
          <Col className='d-none d-lg-block'></Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default JKAccountPreferences