import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useAuth } from '../../context/UserAuth';
import useJamTrackShopping from '../../hooks/useJamTrackShopping';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import JKTooltip from '../common/JKTooltip';

const JKJamTrackPurchaseButton = ({ jamTrack }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation('jamtracks');
  const { addToCart } = useJamTrackShopping();

  const handleAddToCart = () => {
    addToCart(jamTrack);
  }

  return (
    <>
      {jamTrack.purchased ? (
        <span>{t('search.list.purchased')}</span>
      ) : jamTrack.allow_free && currentUser && currentUser.show_free_jamtrack ? (
        <>
          <Button color="primary" onClick={handleAddToCart} size="sm" className="mr-1">
            {t('search.list.get_it_free')}
          </Button>
          <JKTooltip title={t('search.list.get_it_free_help_text')} />
        </>
      ) : jamTrack.added_cart ? (
        <Link to="/shopping-cart">{t('search.list.already_in_cart')}</Link>
      ) : (
        <>
          <div className="fs-1">$ {jamTrack.download_price}</div>
          <Button color="primary" size="sm" className="mr-1" onClick={handleAddToCart}>
            {t('search.list.add_to_cart')}
          </Button>
        </>
      )}
    </>
  );
};

JKJamTrackPurchaseButton.propTypes = {
  jamTrack: PropTypes.object.isRequired
};

export default JKJamTrackPurchaseButton;
