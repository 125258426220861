import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, ListGroup, ListGroupItem, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@farfetch/react-context-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOnScreen from '../../hooks/useOnScreen';
import { Link } from 'react-router-dom';
import { fetchMyJamTracks, filterJamTracks } from '../../store/features/myJamTracksSlice';
import { useDispatch, useSelector } from 'react-redux';

const JKMyJamTracks = () => {
  const { t } = useTranslation('jamtracks');
  const { greaterThan } = useResponsive();
  const [inputValue, setInputValue] = useState('');
  const inputRef = React.createRef();
  const containerRef = useRef(null);
  const [lastJamTrackRef, setLastJamTrackRef] = useState(null);
  const isIntersecting = useOnScreen({ current: lastJamTrackRef });

  const dispatch = useDispatch();

  const jamTracks = useSelector(state => state.myJamTrack.jamTracks);
  const loadingStatus = useSelector(state => state.myJamTrack.status);
  const offset = useSelector(state => state.myJamTrack.next);

  const handleInputChange = e => {
    const val = e.target.value;
    setInputValue(val);
  };

  // useEffect(() => {
  //   dispatch(fetchMyJamTracks());
  // }, []);

  useEffect(() => {
    const getMyJamTracks = setTimeout(async () => {
      await fetchJamTracks({ start: 0, search: inputValue, append: false });
      //dispatch(filterJamTracks(inputValue));
    }, 1000);
    return () => clearTimeout(getMyJamTracks);
  }, [inputValue]);

  const fetchJamTracks = async params => {
    const { page } = params;
    try {
      dispatch(fetchMyJamTracks(params));
    } catch (error) {
      console.log('Error when fetching jam tracks', error);
    } finally {
      //setLoading(false);
    }
  };

  useEffect(() => {
    if (isIntersecting) {
      if (offset && loadingStatus !== 'loading') {
        const params = { start: offset, search: inputValue, append: true };
        fetchJamTracks(params);
      }
    }
  }, [isIntersecting]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '400px',
    overflow: 'auto'
  };

  return (
    <Card style={{ width: greaterThan.sm ? '75%' : '100%' }} className="mx-auto">
      <FalconCardHeader title={t('my.page_title')} titleClass="font-weight-semi-bold" />
      <CardBody className="pt-3">
        <FormGroup className="mb-3">
          <div className="d-flex align-items-center">
            <InputGroup>
              <InputGroupText style={{ borderRadius: '0', borderRight: '0' }}>
                {loadingStatus === 'loading' ? (
                  <span className="spinner-grow spinner-grow-sm" aria-hidden="true" />
                ) : (
                  <FontAwesomeIcon icon="search" transform="shrink-4 down-1" />
                )}
              </InputGroupText>

              <Input
                onChange={handleInputChange}
                value={inputValue}
                innerRef={inputRef}
                placeholder={t('my.search_input.placeholder')}
                data-testid="autocomplete-text"
                type="search"
              />
            </InputGroup>
          </div>
        </FormGroup>
        <div style={containerStyle} ref={containerRef}>
          <ListGroup className="mt-1" data-testid="myJamTrackList">
            {jamTracks &&
              jamTracks.map((jamTrack, index) => (
                <div key={jamTrack.id} ref={ref => (jamTracks.length - 1 === index ? setLastJamTrackRef(ref) : null)}>
                  <ListGroupItem>
                    <Link to={`/jamtrack/${jamTrack.id}`}>{jamTrack.name}</Link>
                    {jamTrack.original_artist && ` by ${jamTrack.original_artist}`}
                  </ListGroupItem>
                </div>
              ))}
          </ListGroup>
          {loadingStatus === 'loading' && <div className="d-flex justify-content-center"> Loading... </div>}
        </div>
      </CardBody>
    </Card>
  );
};

export default JKMyJamTracks;
