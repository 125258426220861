import React, { useEffect, useState } from 'react';
import { Table, Spinner } from 'reactstrap';
//import { useAppData } from '../../context/AppDataContext';
import { removeMixdown } from '../../store/features/jamTrackSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SAMPLE_RATE } from '../../helpers/jamTracks';
import { useJamTrack } from '../../hooks/useJamTrack';
import JKSigningRetryConfirmModal from './JKSigningRetryConfirmModal';
import JKSigningEstimateTimeModal from './JKSigningEstimateTimeModal';
import deleteIcon from '../../assets/img/icons/delete-icon.svg';

const JKMyJamTrackMixes = () => {
  const [selectedMixdown, setSelectedMixdown] = useState(null);
  //const fpPromise = FingerprintJS.load();
  //const { userFingerPrint } = useAppData();
  const dispatch = useDispatch();

  const deleteMixdownStatus = useSelector(state => state.jamTrack.deleteMixdownStatus);

  const { jamTrack,
    mixdowns,
    confirmRetryMixdownSigning,
    enqueuedMixdown,
    showEstimatedTime,
    manageWatchedMixdowns,
    enqueueMixdownForSigning,
    showRetryConfirm,
    setShowRetryConfirm,
    retryConfirmMessage,
    showQueueTime,
    setShowQueueTime,
    enqueueTimeMessage,
    trackName
  } = useJamTrack();

  const deleteMix = mixdown => {
    const { id } = mixdown;
    if (window.confirm('Delete this custom mix?')) {
      console.log('Deleting mixdown', id);
      dispatch(removeMixdown({ id }));
    }
  };

  const confirmRetry = (mixdown) => {
    setSelectedMixdown(mixdown);
    confirmRetryMixdownSigning();
  };

  const retrySigning = () => {
    if (selectedMixdown) {
      const options = {
        id: selectedMixdown.id,
        file_type: 'mp3',
        encrypt_type: null,
        sample_rate: SAMPLE_RATE,
        origin: 'mixes'
      };
      console.log('enqueueMyMixdown', options);
      enqueueMixdownForSigning(options);
    }
  }

  useEffect(() => {
    if (enqueuedMixdown && enqueuedMixdown.origin === 'mixes') {
      //console.log('*_DEBUG_ enqueuedMixdown from mixes', enqueuedMixdown);
      showEstimatedTime();
      manageWatchedMixdowns();
    }
  }, [enqueuedMixdown]);

  const mixdownPackage = mixdown => {
    if (!mixdown.packages || mixdown.packages.length === 0) {
      return null;
    }
    return mixdown.packages[0];
  };

  const isMixdownPackageReady = mixdown => {
    const pkg = mixdownPackage(mixdown);
    return pkg && pkg.signing_state === 'SIGNED';
  };

  return (
    <>
      <p>
        You can save a <strong>maximum of 5 mixes</strong> on JamKazam. If you need to make more mixes, download a mix
        to save it, then delete it to make more room
      </p>
      { mixdowns.length > 0 && (
      <Table striped bordered className="fs--1">
        <thead className="bg-200 text-900">
          <tr>
            <th>Mix</th>
            <th className="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td>Full JamTrack</td>
            <td className="text-center">
              <a onClick={downloadJamTrack}>
                <FontAwesomeIcon icon="download" size="lg" className="mr-3" />
              </a>
            </td>
          </tr> */}
          {mixdowns.map(mixdown => (
            <tr key={mixdown.id}>
              <td>
                {mixdown.name}
              </td>
              <td className="text-center">
                {isMixdownPackageReady(mixdown) ? (
                  <>
                    {/* <a onClick={() => downloadMix(mixdown)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon="download" size="lg" className="mr-3" />
                    </a> */}
                    <a
                      onClick={() => deleteMix(mixdown)}
                      disabled={deleteMixdownStatus === 'loading'}
                      style={{ cursor: 'pointer' }}
                    >
                      {/* <FontAwesomeIcon icon="trash" size="lg" /> */}
                      <img src={deleteIcon} alt="Delete" height={20} />
                    </a>
                  </>
                ) : (
                  <>
                    {mixdownPackage(mixdown) && (mixdownPackage(mixdown).signing_state === 'QUEUED' || mixdownPackage(mixdown).signing_state === 'SIGNING') ? (
                      <Spinner color="primary" size="sm">
                        Loading...
                      </Spinner>
                    ) : (
                      <>
                        { mixdownPackage(mixdown) && ['ERROR', 'SIGNING_TIMEOUT', 'QUEUED_TIMEOUT', 'QUIET'].includes(mixdownPackage(mixdown).signing_state) && (
                        <a onClick={() => confirmRetry(mixdown)} style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon icon="exclamation-circle" size="lg" className="mr-3" />
                        </a>
                        )}
                        <a
                          onClick={() => deleteMix(mixdown)}
                          disabled={deleteMixdownStatus === 'loading'}
                          style={{ cursor: 'pointer' }}
                        >
                          {/* <FontAwesomeIcon icon="trash" size="lg" /> */}
                          <img src={deleteIcon} alt="Delete" height={20} />
                        </a>
                      </>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
          {/* {jamTrack && jamTrack.tracks.map(track => (
            
            <tr key={track.id}>
              <td>
                {trackName(track)}
              </td>
              <td className="text-center">
                <a onClick={() => downloadMix(track)} style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon="download" size="lg" className="mr-3" />
                </a>
              </td>
            </tr>
          ))} */}
        </tbody>
      </Table>
      )}
      <JKSigningRetryConfirmModal
        showRetryConfirm={showRetryConfirm}
        setShowRetryConfirm={setShowRetryConfirm}
        retryConfirmMessage={retryConfirmMessage}
        onConfirm={() => {
          retrySigning();
          setShowRetryConfirm(false);
        }}
      />

      <JKSigningEstimateTimeModal
        showQueueTime={showQueueTime}
        setShowQueueTime={setShowQueueTime}
        enqueueTimeMessage={enqueueTimeMessage}
      />
    </>
  );
};

export default JKMyJamTrackMixes;
