import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useBrowserQuery } from '../../context/BrowserQuery';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { useTranslation } from 'react-i18next';
import JKJamTracksAutoComplete from './JKJamTracksAutoComplete';
import { getJamTracks, autocompleteJamTracks } from '../../helpers/rest';
import JKJamTracksList from './JKJamTracksList';
import JKJamTracksSlider from './JKJamTracksSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShoppingCart } from '../../hooks/useShoppingCart';
import { useResponsive } from '@farfetch/react-context-responsive';
//import { useAppData } from '../../context/AppDataContext';

const JKJamTracksFilter = () => {
  const { t } = useTranslation('jamtracks');
  const [jamTracks, setJamTracks] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [nextOffset, setNextOffset] = useState(null);
  const [totalJamTracks, setTotalJamTracks] = useState(0);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');
  const page = useRef(1);
  const PER_PAGE = 10;
  const { getCartItems, shoppingCart } = useShoppingCart();
  // const { shoppingCart } = useAppData();
  const { greaterThan } = useResponsive();
  const queryString = useBrowserQuery();
  const query = queryString.get('query');
  const artist = queryString.get('artist');

  useEffect(() => {
    getCartItems()
  }, [])

  useEffect(() => {
    if (selected) {
      setSearchTerm(selected.type === 'artist' ? selected.original_artist : selected.name);
    }
  }, [selected]);

  useEffect(() => {
    if(query && !artist) {
      handleOnEnter(query);
    }
    if(artist && !query) {
      handleOnEnter(artist);
    }
  }, [query]);

  useEffect(() => {
    if (artist) {
     setAutoCompleteInputValue(artist);
    }
    if (query) {
      setAutoCompleteInputValue(query);
    }
  }, [artist, query]);

  const queryOptions = selected => {
    const options = {
      per_page: PER_PAGE,
      page: page.current,
    };

    if (typeof selected === 'string') {
      options.search = selected;
      return options;
    }

    if (selected.type === 'artist') {
      options.artist = selected.original_artist;
    } else {
      options.song = selected.name;
    }

    return options;
  };

  const handleOnSelect = async selected => {
    page.current = 1;
    setJamTracks([]);
    setNextOffset(null)
    setSearchTerm('');
    setSelected(selected);
    const params = queryOptions(selected);
    await fetchJamTracks(params);
  };

  const handleOnEnter = async queryStr => {
    page.current = 1;
    setJamTracks([]);
    setNextOffset(null);
    setSelected(x => null);
    setSearchTerm(queryStr);
    const params = queryOptions(queryStr);
    await fetchJamTracks(params);
  };

  const handleOnNextJamTracksPage = async () => {
    const currentQuery = selected ? selected : searchTerm;
    const params = queryOptions(currentQuery);
    await fetchJamTracks(params);
  };

  const fetchJamTracks = async options => {
    try {
      const resp = await getJamTracks(options);
      const data = await resp.json();
      setJamTracks(prev => [...prev, ...data.jamtracks]);
      setNextOffset(data.next);
      setTotalJamTracks(data.count);
      page.current = page.current + 1;
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <Card>
      <FalconCardHeader title={t('search.page_title')} titleClass="font-weight-bold" />
      <CardBody className="pt-3">
        {!greaterThan.sm && (
          <Row>
            <JKJamTrackFilterLinks shoppingCart={shoppingCart} wrapperClassNames='d-flex flex-column' shoppingCartClassNames="col mt-1 mb-2" downloadLinkClassNames='col' />
          </Row>
        )}
        
        <Row>
          <Col>
            <JKJamTracksAutoComplete
              fetchFunc={autocompleteJamTracks}
              onSelect={handleOnSelect}
              onEnter={handleOnEnter}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              inputValue={autoCompleteInputValue}
              setInputValue={setAutoCompleteInputValue}
              inputPlaceholder={t('search.search_input.placeholder')}
            />
          </Col>
          {greaterThan.sm && (
            <Col>
              <JKJamTrackFilterLinks
                shoppingCart={shoppingCart}
                wrapperClassNames="d-flex justify-content-end"
                shoppingCartClassNames="ml-3 mr-1"
              />
            </Col>
          )}
        </Row>
        <>
          {selected?.type && searchTerm.length && jamTracks.length > 0 ? (
            <Row className="mb-2">
              <Col>
                <strong>
                  {t('search.search_results.for_selection')} {selected?.type} "{searchTerm}"
                </strong>
              </Col>
            </Row>
          ) : (
            searchTerm.length > 0 &&
            jamTracks.length > 0 && (
              <Row className="mb-2">
                <Col>
                  <strong>{t('search.search_results.for_search_term')} "{searchTerm}"</strong>
                </Col>
              </Row>
            )
          )}
        </>
        {greaterThan.sm ? (
          <JKJamTracksList
            selectedType={selected?.type}
            searchTerm={searchTerm}
            jamTracks={jamTracks}
            nextOffset={nextOffset}
            onNextPage={handleOnNextJamTracksPage}
          />
        ) : (
          <div className="swiper-container d-block d-md-none">

            <JKJamTracksSlider jamTracks={jamTracks} onNextPage={handleOnNextJamTracksPage} totalJamTracks={totalJamTracks} />

          </div>
        )
        }

      </CardBody>
    </Card>
  );
};

const JKJamTrackFilterLinks = ({ shoppingCart, wrapperClassNames, shoppingCartClassNames, downloadLinkClassNames }) => {
  return (
    <div className={wrapperClassNames}>
      <div className={downloadLinkClassNames}>
        Download JamTracks catalog as a{' '}
        <a
          data-testid="download-pdf"
          href="https://s3.amazonaws.com/jamkazam-public/public/lists/all-jamkazam-jamtracks.pdf"
          target='_blank'
        >
          <strong>PDF file</strong>
        </a>
      </div>
      {shoppingCart && shoppingCart.length > 0 && (
        <div className={shoppingCartClassNames}>
          <Link to="shopping-cart" className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon="shopping-cart" className="mr-1" />
            View Cart ({shoppingCart.length})
          </Link>
        </div>
      )}
    </div>
  );
};

export default JKJamTracksFilter;
